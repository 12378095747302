<template>
  <div>
    <b-card bg-variant="light" header="Residuos Sólidos" header-bg-variant="primary" header-text-variant="white" align="center" >
        
        <b-form @submit="modalEnviar" @reset="cancelar" align="left" id="formularioResiduos">
            <b-form-row>
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-peso"
                        label="Peso generado al mes (Ton)"
                        label-for="peso"
                    >
                        <b-form-input
                            id="peso"
                            v-model="form.peso"
                            type="number"
                            min="1"
                            max="10000"
                            required
                            :disabled="verDetalles?true:false"
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-sector_genera"
                        label="Sector que lo genera"
                        label-for="sector_genera"
                    >
                        <b-form-select id="sector_genera" v-model="form.sector_genera" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">Infraestructura</b-form-select-option>
                            <b-form-select-option value="1">Construcción</b-form-select-option>
                            <b-form-select-option value="2">Demolición Excavación</b-form-select-option>
                            <b-form-select-option value="3">Otro</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.sector_genera == 3">
                    <b-form-group
                        id="group-otro_genera"
                        label="Otro sector"
                        label-for="otro_genera"
                    >
                        <b-form-input
                            id="otro_genera"
                            v-model="form.otro_genera"
                            :disabled="verDetalles?true:false"
                            :required="form.sector_genera==3"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-destino"
                        label="Destino del residuo"
                        label-for="destino"
                    >
                        <b-form-select id="destino" v-model="form.destino" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">Aprovechamiento</b-form-select-option>
                            <b-form-select-option value="1">Disposición Final</b-form-select-option>
                            <b-form-select-option value="2">Demolición</b-form-select-option>
                            <b-form-select-option value="3">Excavación</b-form-select-option>
                            <b-form-select-option value="4">Otro</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.destino == 4">
                    <b-form-group
                        id="group-otro_destino"
                        label="Otro destino"
                        label-for="otro_destino"
                    >
                        <b-form-input
                            id="otro_destino"
                            v-model="form.otro_destino"
                            :disabled="verDetalles?true:false"
                            :required="form.destino == 4"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-forma_separa"
                        label="Forma de separación"
                        label-for="forma_separa"
                    >
                        <b-form-select id="forma_separa" v-model="form.forma_separa" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">Clasificación en obra</b-form-select-option>
                            <b-form-select-option value="1">Otro</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.forma_separa == 1">
                    <b-form-group
                        id="group-otro_forma_separa"
                        label="Otra forma de separación"
                        label-for="otro_forma_separa"
                    >
                        <b-form-input
                            id="otro_forma_separa"
                            v-model="form.otro_forma_separa"
                            :disabled="verDetalles?true:false"
                            :required="form.forma_separa == 1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-medio_transporte"
                        label="Medio de Transporte"
                        label-for="medio_transporte"
                    >
                        <b-form-select id="medio_transporte" v-model="form.medio_transporte" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">Volqueta</b-form-select-option>
                            <b-form-select-option value="1">Camioneta</b-form-select-option>
                            <b-form-select-option value="2">Otro</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.medio_transporte == 2">
                    <b-form-group
                        id="group-otro_medio_trans"
                        label="Otro medio de transporte"
                        label-for="otro_medio_trans"
                    >
                        <b-form-input
                            id="otro_medio_trans"
                            v-model="form.otro_medio_trans"
                            :disabled="verDetalles?true:false"
                            :required="form.medio_transporte == 2"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-cap_carga_trans"
                        label="Cap. carga medio trans. (Ton)"
                        label-for="cap_carga_trans"
                    >
                        <b-form-input
                            id="cap_carga_trans"
                            v-model="form.cap_carga_trans"
                            type="number"
                            min="1"
                            max="50"
                            :disabled="verDetalles?true:false"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-generador"
                        label="Es generador"
                        label-for="generador"
                    >
                        <b-form-select id="generador" v-model="form.generador" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3" v-if="form.generador==1" >
                    <b-form-group
                        id="group-num_generador"
                        label="Núm. licencia de generador."
                        label-for="num_generador"
                    >
                        <b-form-input
                            id="num_generador"
                            v-model="form.num_generador"
                            :disabled="verDetalles?true:false"
                            :required="form.generador==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.generador==1">
                    <b-form-group
                        id="group-archivo_generador"
                        label="Archivo de la licencia gen."
                        label-for="archivo_generador"
                       
                    >
                        <b-form-file
                            id="archivo_generador"
                            :state="Boolean(form.archivo_generador)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenGenerador" 
                            v-if=" !cargadoGenerador"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoGenerador" @click="descargarPDF(form.archivo_generador, 'Generador')">
                            Descargar
                        </b-button>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-transportador"
                        label="Es transportador"
                        label-for="transportador"
                    >
                        <b-form-select id="transportador" v-model="form.transportador" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3" v-if="form.transportador==1">
                    <b-form-group
                        id="group-num_transportador"
                        label="Núm. licencia de transportador."
                        label-for="num_transportador"
                    >
                        <b-form-input
                            id="num_transportador"
                            v-model="form.num_transportador"
                            :disabled="verDetalles?true:false"
                            :required="form.transportador==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.transportador==1">
                    <b-form-group
                        id="group-archivo_transportador"
                        label="Archivo de la licencia trans."
                        label-for="archivo_transportador"
                       
                    >
                        <b-form-file
                            id="archivo_transportador"
                            :state="Boolean(form.archivo_transportador)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenTransportador"
                             v-if=" !cargadoTransportador"
                             :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoTransportador" @click="descargarPDF(form.archivo_transportador, 'Transportador')">
                            Descargar
                        </b-button>
                    </b-form-group>

                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-tratam_aprove"
                        label="Agente tratamiento y aprovechamiento"
                        label-for="tratam_aprove"
                    >
                        <b-form-select id="tratam_aprove" v-model="form.tratam_aprove" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3" v-if="form.tratam_aprove==1">
                    <b-form-group
                        id="group-num_tratam_aprove"
                        label="Núm. licencia de agen."
                        label-for="num_tratam_aprove"
                    >
                        <b-form-input
                            id="num_tratam_aprove"
                            v-model="form.num_tratam_aprove"
                            :disabled="verDetalles?true:false"
                            :required="form.tratam_aprove==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.tratam_aprove==1">
                    <b-form-group
                        id="group-archivo_trata_aprove"
                        label="Archivo de la licencia tratam."
                        label-for="archivo_trata_aprove"
                    >
                        <b-form-file
                            id="archivo_trata_aprove"
                            :state="Boolean(form.archivo_trata_aprove)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenTratamiento"
                            v-if=" !cargadoTramite"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if=" cargadoTramite" @click="descargarPDF(form.archivo_trata_aprove, 'Tratamiento_aprovechamiento')">
                            Descargar
                        </b-button>
                    </b-form-group>

                </b-col>

                <b-col cols="12" md="3">
                    <b-form-group
                        id="group-disp_final"
                        label="Agente disposición final"
                        label-for="disp_final"
                    >
                        <b-form-select id="disp_final" v-model="form.disp_final" class="mb-3" required :disabled="verDetalles?true:false">
                            <b-form-select-option value="0">No</b-form-select-option>
                            <b-form-select-option value="1">Si</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="3" v-if="form.disp_final==1">
                    <b-form-group
                        id="group-num_disp_final"
                        label="Núm. licencia de dispo."
                        label-for="num_disp_final"
                    >
                        <b-form-input
                            id="num_disp_final"
                            v-model="form.num_disp_final"
                            :disabled="verDetalles?true:false"
                            :required="form.disp_final==1"
                        ></b-form-input>
                    </b-form-group>
                </b-col>
                
                <b-col cols="12" md="3" v-if="form.disp_final==1">
                    <b-form-group
                        id="group-archivo_disp_final"
                        label="Archivo de la licencia dispo."
                        label-for="archivo_disp_final"
                    >
                        <b-form-file
                            id="archivo_disp_final"
                            :state="Boolean(form.archivo_disp_final)"
                            placeholder="Elige un archivo"
                            browseText="Buscar"
                            accept="application/pdf"
                            @change="imagenDisposicion"
                            v-if="!cargadoDisposicion"
                            :disabled="verDetalles?true:false"
                        ></b-form-file>

                        <b-button block variant="primary" class="mr-2" v-if="cargadoDisposicion" @click="descargarPDF(form.archivo_disp_final, 'Disposicion_Final')">
                            Descargar
                        </b-button>
                    </b-form-group>

                </b-col>

                <b-col cols="12" class="text-right">
                    <b-button type="submit" variant="primary" class="mr-2" :disabled="verDetalles?true:false">
                        Guardar
                        </b-button>
                    <b-button type="reset" >Cancelar</b-button>
                </b-col>
            </b-form-row>

        </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../contantesErrores';
  export default {
      name: 'ResiduosSolidos',
    data() {
      return {
        editar:false,
        cargadoGenerador: false,
        cargadoTransportador: false,
        cargadoTramite: false,
        cargadoDisposicion: false,
        form: {
            id: null,
            peso: null,
            sector_genera: null,
            otro_genera: null,
            destino: null,
            otro_destino:null,
            forma_separa:null,
            otro_forma_separa:null,
            medio_transporte:null,
            otro_medio_trans:null,
            cap_carga_trans:null,
            generador:null,
            num_generador:null,
            archivo_generador:null,
            transportador:null,
            num_transportador:null,
            archivo_transportador:null,
            tratam_aprove:null,
            num_tratam_aprove:null,
            archivo_trata_aprove:null,
            disp_final:null,
            num_disp_final:null,
            archivo_disp_final:null,
        },
      }
    },
    async mounted(){
        if(this.visitaEnGestion.id){
            this.form.id=this.visitaEnGestion.id
            await this.cargarResiduosSolidos(this.visitaEnGestion.id)
        }
    },
    computed:{
        ...mapState('gestionarVisitas', ['verDetalles','visitaEnGestion', 'resultCreateResSol', 'residuosSolidosCargado' ]),
    },
    methods: {
        ...mapActions(['activarLoading']),
        ...mapActions('gestionarVisitas', ['crearResiduosSolidos', 'cargarResiduosSolidosById', 'editarResiduosSolidos']),

        async cargarResiduosSolidos(id){
            this.activarLoading(true)
            try{
                await this.cargarResiduosSolidosById(id)
                console.log('esto es lo cargado', this.residuosSolidosCargado[0])
                if(this.residuosSolidosCargado.length>0){
                    this.editar=true
                    this.form=this.residuosSolidosCargado[0]
                    this.cargadoGenerador =  this.form.archivo_generador?true:false
                    this.cargadoTransportador =  this.form.archivo_transportador?true:false
                    this.cargadoTramite =  this.form.archivo_trata_aprove?true:false
                    this.cargadoDisposicion =  this.form.archivo_disp_final?true:false
                }
                this.activarLoading(false)
            }catch(e){
                encontrarError(this, e.data)
            }
        },

        imagenGenerador(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'generador')
        },
        
        imagenTransportador(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'transportador')
        },

        imagenTratamiento(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'tratamiento')
        },
        
        imagenDisposicion(e){
            let archivo = e?e.target.files[0]:null
            this.crearBase64(archivo, 'disposicion')
        },

        crearBase64(file, lugar){
            if(file){
                let reader = new FileReader();
                reader.onload=(e)=>{
                    let archivo = e.target.result
                    let base = archivo.split('base64,')
                    let pdf = base[1]

                    if(lugar=='generador'){
                        this.form.archivo_generador = pdf;
                    }
                    if(lugar=='transportador'){
                        this.form.archivo_transportador = pdf;
                    }
                    if(lugar=='tratamiento'){
                        this.form.archivo_trata_aprove = pdf;
                    }
                    if(lugar=='disposicion'){
                        this.form.archivo_disp_final = pdf;
                    }
                }
                reader.readAsDataURL(file)
            }else{
                 if(lugar=='generador'){
                        this.form.archivo_generador = null;
                    }
                    if(lugar=='transportador'){
                        this.form.archivo_transportador = null;
                    }
                    if(lugar=='tratamiento'){
                        this.form.archivo_trata_aprove = null;
                    }
                    if(lugar=='disposicion'){
                        this.form.archivo_disp_final = null;
                    }
            }
        },

        modalEnviar(event){
            event.preventDefault()
             this.$bvModal.msgBoxConfirm('Se van a guardar los datos de Residuos Sólidos, ¿Desea continuar?.', {
                title: 'Por favor confirme',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'primary',
                okTitle: 'Continuar',
                cancelTitle: 'Cancelar',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
                })
                .then(value => {
                    if(value){
                        this.onSubmit()
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        async onSubmit() {
            this.activarLoading(true)

            let dataGuardar = {
                ressollicencia: this.form
            }

             try{
                 if(this.editar){
                     await this.editarResiduosSolidos(dataGuardar)
                 }else{
                     await this.crearResiduosSolidos(dataGuardar)
                 }
                if(this.resultCreateResSol.data.status==200){
                    this.activarLoading(false)
                    this.modalOk()
                }
            }catch(e){
                this.activarLoading(false)
                encontrarError(this, e.data)
            }
        },

        modalOk(){
            this.$bvModal.msgBoxOk('Los Datos fueron guardados correctamente.', {
                title: 'Confirmación',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    // this.$router.push({name: 'gestionVisita'})
                    this.cargarResiduosSolidos(this.visitaEnGestion.id)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        
        cancelar(){
            this.$bvModal.msgBoxOk('Se perderán los datos sin guardar.', {
                title: 'Cancelar',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(() => {
                    this.$router.push({name: 'gestionVisita'})
                })
                .catch(err => {
                    console.log(err)
                })
        }, 
        

        descargarPDF(pdf, nombre) {
            const linkSource = `data:application/pdf;base64,${pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = `licencia_${nombre}.pdf`;
        
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
    }
  }
</script>

<style scoped>
    
</style>